import { Controller, useFormContext } from 'react-hook-form';
import {
  TextField,
  TextFieldProps,
  InputAdornment,
  Select,
  Typography,
  MenuItem,
  BaseTextFieldProps,
} from '@mui/material';
import {
  defaultCountries,
  usePhoneInput,
  FlagEmoji,
  parseCountry,
} from 'react-international-phone';
import React from 'react';
import useLocales from '#/hooks/useLocales';

export interface MUIPhoneProps extends BaseTextFieldProps {
  name: string;
  onChange: (
    e: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  label?: string;
  helperText?: string;
}

type Props = MUIPhoneProps & TextFieldProps;

const PhoneInput = ({
  name,
  label,
  onChange,
  helperText,
  ...restProps
}: Props) => {
  const { translate } = useLocales();
  const { control, watch } = useFormContext();

  const { phone, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: 'ch',
      value: watch(name) || '',
      countries: defaultCountries,
      onChange: (data) => {
        onChange(data.phone);
      },
    });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          label={label || 'Phone'}
          placeholder={label || 'Phone'}
          value={phone}
          onChange={handlePhoneValueChange}
          type="tel"
          inputRef={inputRef}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                style={{ marginRight: '2px', marginLeft: '-8px' }}
              >
                <Select
                  variant="outlined"
                  MenuProps={{
                    style: {
                      height: '300px',
                      width: '360px',
                      top: '10px',
                      left: '-34px',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                  }}
                  sx={{
                    width: 'max-content',
                    fieldset: {
                      display: 'none',
                    },
                    '&.Mui-focused:has(div[aria-expanded="false"])': {
                      fieldset: {
                        display: 'block',
                      },
                    },
                    '.MuiSelect-select': {
                      padding: '8px',
                      paddingRight: '24px !important',
                    },
                    svg: {
                      right: 0,
                    },
                  }}
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  renderValue={(value) => (
                    <FlagEmoji iso2={value} style={{ display: 'flex' }} />
                  )}
                >
                  {defaultCountries
                    .filter((country) => {
                      const { iso2 } = parseCountry(country);
                      return ['ch', 'de', 'fr', 'it'].includes(iso2);
                    })
                    .map((c) => {
                      const country = parseCountry(c);
                      return (
                        <MenuItem
                          key={country.iso2}
                          value={country.iso2}
                          sx={{ width: 'max-content' }}
                        >
                          <FlagEmoji
                            iso2={country.iso2}
                            style={{ marginRight: '8px' }}
                          />
                          <Typography variant="caption" marginRight="8px">
                            {country.name}
                          </Typography>
                          <Typography variant="caption" color="gray">
                            +{country.dialCode}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                </Select>
              </InputAdornment>
            ),
          }}
          error={!!error}
          helperText={error ? String(translate(error?.message)) : helperText}
          {...restProps}
          sx={{
            width: 1,

            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: (theme) => theme.palette.divider,
              },
            },
          }}
        />
      )}
    />
  );
};

export default PhoneInput;
